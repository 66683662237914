<template>
  <div v-if="interpret" id="interpret">
    <app-navbar></app-navbar>
    <div class="whiteBalls">
      <img class="img-fluid" src="@/assets/whiteBalls.png" alt="whiteBalls">
    </div>
    <div class="redBrickBallon">
      <img class="img-fluid" src="@/assets/redBrickBallon.png" alt="redBrickBallon">
    </div>
    <div>
      <a v-on:click="$refs.galleryModal.show(getInterpretImgUrl(interpret.id))" class="d-block mb-4 h-100">
        <img id="ImgNew" class="img-fluid gallery-img cropped-img position-img-interpret gallery-picture"
             :src="getInterpretImgUrl(interpret.id)"
             alt="interpret">
      </a>
    </div>
    <div class="interpretBlock pb-lg-4">
      <div class="container">
        <div class="row">
          <div id="myModal" class="modal">
            <span class="close">✕</span>
            <img class="modal-content img-fluid" id="img01">
          </div>
          <div class="col-lg-5">
          </div>
          <div class="col-lg-7">
            <h2>{{ interpret.name | translation }}</h2>
            <div class="pb-lg-4">
              <div class="name-genre" v-for="genre in interpret.genres" v-bind:key="genre.id">
                <p class="pr-lg-4 name-genre">{{ genre.name | translation }}</p>
                <p :style="'background-color: ' + genre.color" class="genre-color"></p>
              </div>
              <div v-html="html" class="textInterpret pb-lg-4 text-justify">
              </div>
              <div class="row pt-lg-2" v-for="event in interpret.events" v-bind:key="event.id">
                <div class="col-lg-3 text-center">
                  <p class="pt-lg-2">{{ event.stage.name | translation }}</p>
                </div>
                <div class="col-lg-3 text-center">
                  <p class="pt-lg-2 d-inline-block">{{ event.timeFrom | time }}</p>
                  <p class="d-inline-block pl-lg-2 pr-lg-2" v-if="event.timeTo">-</p>
                  <p class="d-inline-block">{{ event.timeTo | time }}</p>
                </div>
                <div class="col-lg-3 text-center">
                  <p class="pt-lg-2">{{ event.date | date }}</p>
                </div>
                <div class="col-lg-3 text-center">
                  <a v-if="event.ticketLink && event.ticketLink !== 'free'" :href="event.ticketLink">
                    <div type="button" class="btn">{{ "Interpret.tickets" | staticTranslation }}</div>
                  </a>
                  <a v-if="event.ticketLink === 'free'">
                    <div type="button" class="btn">{{ "Interpret.ticketsFree" | staticTranslation }}</div>
                  </a>
                </div>
                <div class="col-lg-12 text-center">
                  <small><p>{{ event.collaboration | translation }}</p></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1 offset-lg-11 redBallone">
      <img class="img-fluid" src="@/assets/redBallone.png" alt="redBallone">
    </div>
    <gallery-modal ref="galleryModal"/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import axios from "@/api/axiom-instance";
import config from "@/config";
import footer from "@/components/base/footer";
import GalleryModal from "@/components/base/GalleryModal";

export default {
  name: "Interpret",
  components: {
    GalleryModal,
    'app-navbar': navbar,
    'app-footer': footer,
    'gallery-modal': GalleryModal
  },
  data() {
    return {
      interpret: {},
    }
  },
  computed: {
    html: function () {
      return this.$options.filters.translation(this.interpret.description);
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    load: function (id) {
      axios
          .get("/interpret/extended", {params: {id: id}})
          .then((success) => {
            this.interpret = success.data;
          })
    },
    getInterpretImgUrl: function (id) {
      if (id) {
        return config.API_URL + "/image/preview?type=interprets&name=" + id
      } else {
        return ""
      }
    }
  }
}
</script>

<style scoped>

h1 {
  font-size: 3.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h2 {
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

h3 {
  font-family: "Transcript Pro";
  color: white;
}

a {
  color: #152326;
  text-decoration: none;
  font-size: 1em;
  font-family: 'Transcript Mono';
}

p {
  font-size: 1em;
  margin-bottom: 0;
  font-family: "Transcript Pro";
  color: #152326;
}

hr {
  padding-top: 1em;
  border-top: solid 1px #152326;
}

.textInterpret {
  color: #152326;
  font-family: "Transcript Mono";
  font-size: 1.2em;
  padding-top: 1em;
}

.interpretBlock {
  margin-top: 63vh;
  margin-right: 13%;
  padding-top: 1em;
  background-color: #ED5B2D;
}

.redBallone {
  width: 5%;
  padding-bottom: 10em;
}

#interpret {
  background-color: #FFCE6D;
  min-height: 50vh;
}

.whiteBalls {
  position: absolute;
  width: 6%;
  top: 79vh;
  left: 7%;
}

.redBrickBallon {
  position: absolute;
  text-align: end;
  width: 14%;
  right: 25%;
  top: 20%;
}

.position-img-interpret {
  position: absolute;
  top: 30vh;
  left: 14%;
}

.cropped-img {
  height: 100%;
  width: auto;
  max-height: 330px;
  max-width: 300px;
  object-fit: cover;
}

.btn {
  padding: 5px;
  border-radius: 0px;
  border: solid 1px #152326;
  color: #152326;
}

.btn:hover {
  background-color: #152326;
  color: #eae7d6;
}

.genre-color {
  display: inline-block;
  clip-path: circle(50% at 50% 50%);
  padding: 6px;
  margin-right: .8em;
}

.name-genre {
  display: inline-block;
  margin-bottom: 0;
}

@media screen and (min-width: 1101px) and (max-width: 1345px) {
  .position-img-interpret {
    left: 7%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
  .position-img-interpret {
    left: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .position-img-interpret {
    top: 17vh;
    left: 29%;
  }
}

@media screen and (min-width: 470px) and (max-width: 767px) {
  .position-img-interpret {
    top: 20vh;
    left: 31%;
  }

  .cropped-img {
    max-height: 250px;
    max-width: 250px;
  }
}

@media screen and (min-width: 345px) and (max-width: 469px) {
  .position-img-interpret {
    top: 20vh;
    left: 18%;
  }

  .interpretBlock {
    margin-right: 0;
  }

  .cropped-img {
    max-height: 200px;
    max-width: 250px;
  }
}

@media (max-width: 344px) {
  .position-img-interpret {
    top: 20vh;
    left: 10%;
  }

  .interpretBlock {
    margin-right: 0;
  }

  .cropped-img {
    max-height: 200px;
    max-width: 250px;
  }
}

@media (max-width: 1000px) {
  .whiteBalls,
  .redBrickBallon,
  .redBallone {
    display: none;
  }


}

</style>
